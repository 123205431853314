import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import Title from "../../Components/Title";
import Copyright from "../../Components/Copyright";
import NewTypography from "../../Components/NewTypography";
import SupportTypography from "./SupportTypography";
import EmailTypography from "../../Components/EmailTypography";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../../Components/Navbar";
import SupportNewTypography from "./SupportNewTypography";

const Support = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Container>
      <Navbar />
      <Box
        sx={{ color: "#000", mt: "2rem" }}
        data-aos="fade-up"
        data-aos-duration="500"
      >
        <Title title={`GO Services`} paddingb={"1.5rem"} />
        <Typography
          sx={{
            fontSize: { sm: "22px", xs: "18px" },
            fontFamily: "ABLE",
            marginTop: 0,
            lineHeight: { sm: "32px", xs: "30px" },
            textAlign: { sm: "start", xs: "justify" },
            color: "#040404",
          }}
        >
          It is highly recommended that all CapnoTrainer GO users read the
          software Manuals and watch the tutorial videos for answers to their
          questions. These support materials are available at{" "}
          <a
            style={{ fontWeight: "600", color: "rgb(109 79 160)" }}
            href="https://www.betterphysiology.com/"
            target="_blank"
          >
            www.betterphysiology.com
          </a>{" "}
          as well as within the BASIC and PROFESSIONAL software programs
          themselves.
        </Typography>
        <Box pt={"1.5rem"}>
          <NewTypography
            text={"Hardware OR installation issues?"}
            color={"#FF0000"}
            // color={"rgb(109 79 160)"}
            fsm={"26px"}
            fxs={"22px"}
            lsm={"35px"}
            lxs={"35px"}
            fontWeight={600}
            textalign={"justify"}
          />
        </Box>
        <Box pt={"0.5rem"}>
          <SupportNewTypography
            text={
              <>
                If you have a hardware or installation issue, call{" "}
                <b>1.800.850.9795</b> or email
              </>
            }
            email={"tech@betterphysiology.com"}
            text2={" to make an appointment for immediate service!"}
            fsm={"22px"}
            fxs={"18px"}
            lsm={"32px"}
            lxs={"30px"}
            textalign={"justify"}
            color={"#040404"}
          />
        </Box>
        <Box pt={"1.5rem"}>
          <NewTypography
            text={"Professional GO Users and their associated Trainers:"}
            fsm={"26px"}
            fxs={"22px"}
            lsm={"35px"}
            lxs={"35px"}
            textalign={"justify"}
            fontWeight={600}
          />
        </Box>
        <Box pt={"0.5rem"}>
          <SupportTypography
            text1={
              "Set an appointment on Zoom with the Better Physiology Tech System at "
            }
            text2={
              <>
                for software and Cloud database account issues. You may also
                call <b>1.800.850.9795</b>. Your professional subscription
                includes these live services at no cost to you or your associate
                trainers.
              </>
            }
            link={"tech@betterphysiology.com"}
            fsm={"22px"}
            fxs={"18px"}
            lsm={"32px"}
            lxs={"30px"}
            color={"#040404"}
          />
        </Box>
        <Box pt={"1.5rem"}>
          <NewTypography
            text={"Personal and Basic GO users:"}
            fsm={"26px"}
            fxs={"22px"}
            lsm={"35px"}
            lxs={"35px"}
            textalign={"justify"}
            fontWeight={600}
          />
        </Box>
        <Box pt={"0.5rem"}>
          <Typography
            sx={{
              fontSize: { sm: "22px", xs: "18px" },
              fontFamily: "ABLE",
              marginTop: 0,
              lineHeight: { sm: "32px", xs: "30px" },
              textAlign: { sm: "start", xs: "justify" },
              color: "#040404",
            }}
          >
            Read the BASIC MANUAL and watch specifically relevant video
            tutorials available at{" "}
            <a
              style={{ fontWeight: "600", color: "rgb(109 79 160)" }}
              href="https://www.betterphysiology.com/"
              target="_blank"
            >
              www.betterphysiology.com
            </a>{" "}
            as well as within the software itself. Live tech support on Zoom is
            available for a fee of $25.00 per 15 minutes of service by
            appointment with the Better Physiology Tech System. Go to{" "}
            <a
              style={{ fontWeight: "600", color: "rgb(109 79 160)" }}
              href="mailto:tech@betterphysiology.com"
            >
              tech@betterphysiology.com
            </a>{" "}
            or call <b>1.800.850.9795</b> to set an appointment.
            <br />
          </Typography>
          <Typography
            sx={{
              pt: "0.5rem",
              fontSize: { sm: "22px", xs: "18px" },
              fontFamily: "ABLE",
              marginTop: 0,
              lineHeight: { sm: "32px", xs: "30px" },
              textAlign: { sm: "start", xs: "justify" },
              color: "#040404",
            }}
          >
            Tech Support does NOT provide free software instructional services.
            You may pay for live one-on-one tutorial service as described later.
          </Typography>
        </Box>
        <Box pt={"1.5rem"}>
          <NewTypography
            text={<>WARRANTY</>}
            fsm={"26px"}
            fxs={"22px"}
            lsm={"35px"}
            lxs={"35px"}
            textalign={"justify"}
            fontWeight={600}
          />
        </Box>
        <Box pt={"0.5rem"}>
          <NewTypography
            text={
              <>
                <b>Standard Warranty</b>: The CapnoTrainer GO warranty is one
                year and includes parts, labor, and return shipment. The
                Warranty begins on the day of shipment following initial
                purchase.
              </>
            }
            fsm={"22px"}
            fxs={"18px"}
            lsm={"32px"}
            lxs={"30px"}
            textalign={"justify"}
            color={"#040404"}
          />
        </Box>
        <Box pt={"0.5rem"}>
          <NewTypography
            text={
              <>
                <b>Extended Warranty Program</b>: The program is for a two-year
                period and includes parts, labor, return shipment, AND
                accidental damage. The extended program is available only at the
                GO initial purchase time and before shipment for a fee of
                $300.00.
              </>
            }
            fsm={"22px"}
            fxs={"18px"}
            lsm={"32px"}
            lxs={"30px"}
            textalign={"justify"}
            color={"#040404"}
          />
        </Box>
        <Box pt={"0.5rem"}>
          <NewTypography
            text={
              <>
                <b>Making a Claim</b>: In the event of a warranty claim, send an
                email to{" "}
                <a
                  style={{ fontWeight: "600", color: "rgb(109 79 160)" }}
                  href="mailto:tech@betterphysiology.com"
                >
                  tech@betterphysiology.com
                </a>
                . Unless an email is sent to Better Physiology on or before the
                last day of the warranty time period, the warranty will be
                considered expired.
              </>
            }
            fsm={"22px"}
            fxs={"18px"}
            lsm={"32px"}
            lxs={"30px"}
            textalign={"justify"}
            color={"#040404"}
          />
        </Box>

        <Box pt={"1.5rem"}>
          <NewTypography
            text={
              <>
                <b style={{ color: "#000" }}>Software</b> TUTORIAL SERVICES
              </>
            }
            fsm={"26px"}
            fxs={"22px"}
            lsm={"35px"}
            lxs={"35px"}
            textalign={"justify"}
            fontWeight={600}
          />
        </Box>
        <Box pt={"0.5rem"}>
          <NewTypography
            text={`Many people learn to use the software more efficiently through receiving
one-on-one live help from a technology expert rather than by reading
manuals and watching videos. Personal attention while learning the details
of sophisticated software means learning to take advantage of its many
perhaps otherwise overlooked features. `}
            fsm={"22px"}
            fxs={"18px"}
            lsm={"32px"}
            lxs={"30px"}
            textalign={"justify"}
            color={"#040404"}
          />
        </Box>
        <Box pt={"0.5rem"}>
          {/* <NewTypography
            text={
              "Better Physiology offers one-on-one tutorial services for a fee of $25.00 per 15 minutes of service. Contact tech@betterphysiology.com to make an appointment. This service is above and beyond the trouble-shooting services provided at no charge for Professional GO users who receive these services as part of their subscription."
            }
            fsm={"24px"}
          fxs={"19px"}
          lsm={"32px"}
          lxs={"30px"}
            textalign={"justify"}
            color={"#040404"}
          /> */}
          <SupportTypography
            text1={
              "Better Physiology offers one-on-one tutorial services for a fee of $25.00 per 15 minutes of service. Contact "
            }
            text2={
              "to make an appointment. This service is above and beyond the trouble-shooting services provided at no charge for Professional GO users who receive these services as part of their subscription."
            }
            link={"tech@betterphysiology.com"}
            fsm={"22px"}
            fxs={"18px"}
            lsm={"32px"}
            lxs={"30px"}
          />
        </Box>
        <Box pt={"0.5rem"}>
          <SupportTypography
            text1={
              <>
                Better Physiology DOES NOT educate customers on how to provide
                breathing behavior analysis or CapnoLearning services. Consult
                the book, <em>CapnoLearning: An Introductory Guide</em>, or call{" "}
                <b>1.800.850.9795</b> for CapnoLearning consultation services as
                described below.
              </>
            }
            fsm={"22px"}
            fxs={"18px"}
            lsm={"32px"}
            lxs={"30px"}
          />
        </Box>
      </Box>
      <Box pt={"3rem"}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default Support;

import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import Title from "../Components/Title";
import Copyright from "../Components/Copyright";
import consult_img from "../Assets/Images/consult_img.jpg";
import NewTypography from "../Components/NewTypography";
import EmailTypography from "../Components/EmailTypography";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../Components/Navbar";

const Consultation = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Container>
      <Navbar />
      <Box
        sx={{ color: "#000", mt: "2rem" }}
        data-aos="fade-up"
        data-aos-duration="500"
      >
        <Title title={<><b style={{color:"#000"}}>CapnoLearning</b> CONSULTATION SERVICES</>} paddingb={"1.5rem"} />
        <Box sx={{ width: { lg: "65%", md: "60%", xs: "100%" } }}>
 
           <Typography
      sx={{
        fontSize: { sm: "22px", xs: "19px" },
        fontFamily: "ABLE",
        lineHeight: { sm: "32px", xs: "30px" },
        textAlign: { sm: "start", xs: "justify" },
      }}
    >
     CapnoLearning consultation services can be obtained from Behavioral
     Physiology Institute by calling 
     {" "}<b>1.800.850.9795</b>{" "}or emailing{" "}
      
        <Typography
          component={"a"}
          href={`mailto:sr@bp.edu`}
          sx={{
            fontSize: { sm: "22px", xs: "19px" },
            fontFamily: "ABLE",
            lineHeight: { sm: "32px", xs: "30px" },
            color:"rgb(109 79 160)",
            fontWeight:600
            // textAlign: { sm: "start", xs: "justify" },
          }}
        >
          sr@bp.edu
        </Typography>.
        {" "}Set
        an appointment by going to
        {" "}
        <a href="https://www.respimatters.com/" target="_blank">www.CapnoLearning.org</a>.
    </Typography>
          <Box pt={"1.5rem"}>
            <NewTypography
              text={
                `The Institute provides breathing assessment and learning sessions for
Personal GO users, CapnoLearning instructional services for Professional GO
practitioners who need assistance, and case review for practitioners
wanting advice and feedback on specific cases.`
              }
              fsm={"22px"}
              fxs={"19px"}
              lsm={"32px"}
              lxs={"30px"}
              textalign={"justify"}
              color={"#040404"}
            />
          </Box>
          <Box pt={"1.5rem"}>
            <NewTypography
              text={
                `The institute provides CapnoLearning practicum supervision for the
Professional School of Behavioral Health Sciences that trains practitioners to
become Certified as Breathing Behavior Analysts as well as those seeking
the Professional Diploma in Behavioral Breathing Science.`
              }
              fsm={"22px"}
              fxs={"19px"}
              lsm={"32px"}
              lxs={"30px"}
              textalign={"justify"}
              color={"#040404"}
            />
          </Box>
          <Box pt={"1.5rem"}>
            <NewTypography
              text={
             `The Institute manages a network of Certified Breathing Behavior Analysts
worldwide, who can provide breathing learning services for those with
dysfunctional breathing habits and/or for those interested in optimizing
respiration and its associated acid-base physiology.`
              }
              fsm={"22px"}
              fxs={"19px"}
              lsm={"32px"}
              lxs={"30px"}
              textalign={"justify"}
              color={"#040404"}
            />
          </Box>
        </Box>
        <Box
          pt={"2.5rem"}
          sx={{ m: "0 auto", display: { md: "block", xs: "none" } }}
        >
          <Typography
            component={"img"}
            src={consult_img}
            style={{
              position: "absolute",
              width: "380px",
              right: "0px",
              top: "20px",
            }}
            mt={"60px"}
            width={"100%"}
            borderRadius={"20px"}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: { md: "none", xs: "block" },
          width: { sm: "80%", xs: "100%" },
          margin: "0 auto",
        }}
      >
        <Typography
          component={"img"}
          src={consult_img}
          width={"100%"}
          mt={"2rem"}
          borderRadius={"20px"}
        />
      </Box>

      <Box
        sx={{
          display: "none",
          height: { lg: "10vh", xs: "0" },
          "@media(min-width:1300px)": {
            display: "block",
          },
        }}
      />
      <Box pt={"3rem"}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default Consultation;

import React, { useEffect, useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import Title from "../Components/Title";
import Copyright from "../Components/Copyright";
import interview_img from "../Assets/Images/interview_img.jpg";
import NewTypography from "../Components/NewTypography";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../Components/Navbar";
import InterviewTypogray from "./InterviewTypogray";
import InterviewVideo from "../Components/InterviewVideo";

const Interview = () => {
  const [videoLink, setVideoLink] = useState("");
  const [show, setShow] = useState(false);
  const [interviewModal, setInterviewModal] = useState(false);
  const handleVideo = (link) => {
    setVideoLink(link);
    setInterviewModal(true);
  };
  useEffect(() => {
    AOS.init();
  }, []);

  const url1 = `https://better-physiology-videos.s3.amazonaws.com/Interview-with-Sandy-Abrams-Nov-8-2021.mp4`;
  const url2 = `https://better-physiology-videos.s3.amazonaws.com/Denise-Campbell-2-cases.mp4`;

  const List = [
    [
      "Dr Mercola introduces Dr Litchfield",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip1+-+Dr+Mercola+introduces+Dr+Litchfield.mp4",
      "58 sec"
    ],
    [
      "Dr Mercola - CO2 is vital to good health",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip2+-+Dr+Mercola+-+CO2+is+vital+to+good+health+.mp4",
       "58 sec"
    ],
    [
      "Dr Mercola - CO2 is a critical missing link",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip3+-+Dr+Mercola+-+CO2+is+a+critical+missing+link.mp4",
       "1 min 43 sec"
    ],
    [
      "Dr Mercola - Capnolearning Technology",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip4+-+Dr+Mercola+-+Capnolearning+Technology.mp4",
      "42 sec"
    ],
    [
      "Dr Litchfield - Breathing Habit Triggers",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip5+-+Dr+Litchfield+-+Breathing+Habit+Triggers.mp4",
      "2 min 20 sec"
    ],
    [
      "Dr Mercola and Dr Litchfield talk about the CapnoTrainer",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip6+-+Dr+Mercola+and+Dr+Litchfield+talk+about+the+CapnoTrainer.mp4",
      "3 min 05 sec"
    ],
    [
      "Dr Litchfield explains symptom misinterpretations",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip7+-+Dr+Litchfield+explains+symptom+misinterpretations.mp4",
      "24 sec"
    ],
    [
      "Dr Litchfield and Dr Mercola discuss the role of trust in breathing",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip8+-+Dr+Litchfield+and+Dr+Mercola+discuss+the+role+of+trust+in+breathing.mp4",
      "2 min 06 sec"
    ],
    [
      "Dr Mercola - The CapnoTrainer and my bad breathing habit",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip9+-+Dr+Mercola+-+The+CapnoTrainer+and+my+bad+breathing+habit.mp4",
      "1 min 24 sec"
    ],
    [
      "Dr Litchfield - Mechanical vs behavioral explanations of breathing technique outcomes",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip10+-+Dr+Litchfield+-+Mechanical+vs+behavioral+explanations+of+breathing+technique+outcomes.mp4",
      "58 sec"
    ],
    [
      "Dr Mercola and Dr Litchfield discuss Buteyko Breathing",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip11+-+Dr+Mercola+and+Dr+Litchfield+discuss+Buteyko+Breathing.mp4",
      "6 min 18 sec"
    ],
    [
      "Dr Mercola hypocapnia is a very serious issue",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip12+-+Dr+Mercola+hypocapnia+is+a+very+serious+issue.mp4",
      "24 sec"
    ],
    [
      "Dr Mercola introduces breathing behavioral analysis",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip13+-+Dr+Mercola+introduces+breathing+behavioral+analysis.mp4",
      "1 min 36 sec"
    ],
    [
      "Dr Litchfield describes breathing behavioral analysis",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip14+-+Dr+Litchfield+describes+breathing+behavioral+analysis.mp4",
      "6 min 32 sec"
    ],
    [
      "Dr Litchfield - Physiology is a learning system",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip15+-+Dr+Litchfield+-+Physiology+is+a+learning+system.mp4",
      "1 min 11 sec"
    ],
  ];

  return (
    <Container>
      <Navbar />
      <Box
        sx={{ color: "#000", mt: "2rem" }}
        data-aos="fade-up"
        data-aos-duration="500"
        width={"100%"}
        display={"block"}
      >
        <Title title={`Interviews`} paddingb={"0rem"} />

        <Box sx={{ pt: "1rem" }}>
          <Box sx={{ width: { md: "65%", xs: "100%" } }}>
            <Typography
              sx={{
                fontSize: { sm: "1.375rem", xs: "1.2rem" },
                lineHeight: { sm: "2.2rem", xs: "2rem" },
                fontFamily: "ABLE",
                textAlign: { sm: "start", xs: "justify" },
                color: "#040404",
                mb: "1rem",
              }}
            >
              <b>Dr. Mercola interviews Dr. Litchfield.</b>
              <br />
              <Typography
                component={"a"}
                sx={{
                  textDecoration: "underline",
                  color: "rgb(109 79 160)",
                  fontSize: { sm: "1.275rem", xs: "1.2rem" },
                  lineHeight: { sm: "2.2rem", xs: "2rem" },
                  fontFamily: "ABLE",
                  cursor: "pointer",
                  fontWeight: 600,
                }}
                onClick={() => setShow(!show)}
              >
                Click here
              </Typography>{" "}
              to choose and view short clips of Dr. Litchfield being interviewed
              by Dr. Mercola.
            </Typography>
            {show && (
              <Box pb={"1rem"}>
              <Typography sx={{fontSize:"1.3rem",
              mb:"0.5rem",
                      fontFamily: "ABLE",
                      fontWeight:"600",}}>Click on the clip names to watch them. <a style={{"textDecoration" : "underline", color: "rgb(109 79 160)", cursor: "pointer"}} onClick={() => setShow(!show)}>Hide List</a></Typography>
                {List?.map((data, index) => (
                 <Typography    key={index} sx={{
                   fontSize: { sm: "1.275rem", xs: "1.2rem" },
                      lineHeight: { sm: "2.2rem", xs: "2rem" },
                      fontFamily: "ABLE",
                      pb: "0.5rem",
                     
                      // color: "rgb(109 79 160)",
                 }}>
                 {index+1}){" "}
                 <Typography
                     component="a"
                    sx={{
                      fontSize: { sm: "1.275rem", xs: "1.2rem" },
                      lineHeight: { sm: "2.2rem", xs: "2rem" },
                      fontFamily: "ABLE",
                      textAlign: { sm: "start", xs: "justify" },
                      color: "rgb(109 79 160)",
                      textDecoration: "underline",
                    
                      cursor:"pointer"
                    }}
                    onClick={() => handleVideo(data[1])}
                  >
                    {data[0]} 
                  </Typography>{" "}
                  ({data[2]})
                 </Typography>
                ))}
              </Box>
            )}
            <Typography
              sx={{
                fontSize: { sm: "1.375rem", xs: "1.2rem" },
                lineHeight: { sm: "2.2rem", xs: "2rem" },
                fontFamily: "ABLE",
                textAlign: { sm: "start", xs: "justify" },
                color: "#040404",
              }}
            >
              {" "}
              <b>Sandy Abrams interviews Dr. Litchfield.</b>
              <br />
              <Typography
                component={"a"}
                sx={{
                  textDecoration: "underline",
                  color: "rgb(109 79 160)",
                  fontSize: { sm: "1.275rem", xs: "1.2rem" },
                  lineHeight: { sm: "2.2rem", xs: "2rem" },
                  fontFamily: "ABLE",
                  cursor: "pointer",
                  fontWeight: 600,
                }}
                onClick={() => handleVideo(url1)}
              >
                Click here
              </Typography>{" "}
              to see an interview with Peter Litchfield and Sandy Abrams
              regarding breathing misconceptions, myths, and pseudoscience:{" "}
            </Typography>
            <Box sx={{ pt: "1rem" }}>
              <Typography
                sx={{
                  fontSize: { sm: "1.375rem", xs: "1.2rem" },
                  lineHeight: { sm: "2.2rem", xs: "2rem" },
                  fontFamily: "ABLE",
                  textAlign: { sm: "start", xs: "justify" },
                  color: "#040404",
                }}
              >
                <b>Dr. Litchfield interviews Denise Campbell.</b>
                <br />
                <Typography
                  component={"a"}
                  sx={{
                    textDecoration: "underline",
                    color: "rgb(109 79 160)",
                    fontSize: { sm: "1.275rem", xs: "1.2rem" },
                    lineHeight: { sm: "2.2rem", xs: "2rem" },
                    fontFamily: "ABLE",
                    cursor: "pointer",
                    fontWeight: 600,
                  }}
                  onClick={() => handleVideo(url2)}
                >
                  Click here
                </Typography>{" "}
                to see an interview with Peter Litchfield and Denise Campbell
                regarding a breathing behavior analysis of two clients: <br />
              </Typography>
            </Box>
          </Box>

          <Box
            pt={"0rem"}
            sx={{
              float:"right",
              display: { md: "block", xs: "none" },
              width: { md: "50%", xs: "50%" },
              m: "0 auto",
            }}
          >
            {" "}
            <Typography
              component={"img"}
              style={{
                position: "absolute",
                width: "400px",
                right: "0px",
                top: "20px",
              }}
              src={interview_img}
              width={"70%"}
            />
          </Box>
          <Box
            pt={"2rem"}
            sx={{
              display: { md: "none", xs: "block" },
              width: { sm: "80%", xs: "100%" },
              margin: "0 auto",
            }}
          >
            <Typography component={"img"} src={interview_img} width={"100%"} />
          </Box>
        </Box>
      </Box>
      <Box sx={{ height: { md: "30vh", xs: "0" } }} />
      <Box pt={"3rem"}>
        <Copyright />
      </Box>

      <InterviewVideo
        interviewModal={interviewModal}
        setInterviewModal={setInterviewModal}
        videoLink={videoLink}
      />
    </Container>
  );
};

export default Interview;

import React, { useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Title from "../../Components/Title";
import NewTypography from "../../Components/NewTypography";
import Copyright from "../../Components/Copyright";
import AOS from "aos";
import "aos/dist/aos.css";
import EmailTypography from "../../Components/EmailTypography";
import contact from "../../Assets/Images/contact.png";
import Navbar from "../../Components/Navbar";
import NewTypographyTech from "./NewTypographyTech";

import phone3 from "../../Assets/Images/new_phone_icon.png";
import contact_1 from "../../Assets/Images/contact-us_new.png";

const ContactUs = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Container>
      <Navbar />
      <Box sx={{ color: "#000", mt: "2rem" }} data-aos="fade-up">
        <Box position={"relative"}>
          <Title title={`Contact Us`} paddingb={"1.5rem"} />
          <Typography
            component={"img"}
            src={contact_1}
            sx={{
              display: "none",
              width: "300px",
              "@media (min-width:1025px)": {
                position: "absolute",
                // left: "30%",
                top: "42%",
                right: "0",
                transform: "translateY(-45%)",
                display: "block",
                // filter: "hue-rotate(75deg)",
              },
            }}
          />
          <Typography
            sx={{
              fontSize: "30px",
              fontFamily: "ABLE",
              lineHeight: { sm: "2rem" },
              textAlign: { sm: "start", xs: "center" },
              pb: "2.5rem",
              borderBottom: "1px solid rgb(109 79 160)",
            }}
          >
            BETTER PHYSIOLOGY LTD.
          </Typography>
        </Box>
        <Grid container spacing={2} mt={"1rem"}>
          <Grid item md={6} sm={6} xs={12}>
            <Box>
              <NewTypography
                text={`Corporate Headquarters`}
                // fsm={"34px"}
                // fxs={"28px"}
                // lsm={"45.88px"}
                // lxs={"45px"}
                fontWeight={600}
                fsm={"28px"}
                fxs={"22px"}
                lsm={"40px"}
                lxs={"28px"}
              />
              <NewTypography
                text={`109 East 17th Street,`}
                fsm={"22px"}
                fxs={"20px"}
                lsm={"40px"}
                lxs={"28px"}
                color={"#040404"}
              />
              <NewTypography
                text={` Cheyenne, WY 82001 USA`}
                fsm={"22px"}
                fxs={"20px"}
                lsm={"40px"}
                lxs={"28px"}
                color={"#040404"}
              />
            </Box>
            <Box py={"1.5rem"}>
              <NewTypography
                text={`Santa Fe Office`}
                fsm={"28px"}
                fontWeight={600}
                fxs={"22px"}
                lsm={"40px"}
                lxs={"28px"}
              />
              <NewTypography
                text={`7 Camino de Rey Cir`}
                fsm={"22px"}
                fxs={"20px"}
                lsm={"40px"}
                lxs={"28px"}
                color={"#040404"}
              />
              <NewTypography
                text={`Santa Fe, NM 87506 USA`}
                fsm={"22px"}
                fxs={"20px"}
                lsm={"40px"}
                lxs={"28px"}
                color={"#040404"}
              />
            </Box>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <Box>
              {/* <NewTypography
                text={`Tel, Admin: 307.773.0888,`}
                fsm={"22px"}
                fxs={"20px"}
                lsm={"40px"}
                lxs={"28px"}
                color={"#040404"}
              />
              <NewTypography
                text={`Tel, Sales: 505.820.3376`}
                fsm={"22px"}
                fxs={"20px"}
                lsm={"40px"}
                lxs={"28px"}
                color={"#040404"}
              />
              <NewTypography
                text={`Tel, Tech Support: +1 (800) 235-1189`}
                fsm={"22px"}
                fxs={"20px"}
                lsm={"40px"}
                lxs={"28px"}
                color={"#040404"}
              />
              <NewTypography
                text={`Tel, Data & Sales Support: +1 (800) 253-6915`}
                fsm={"22px"}
                fxs={"20px"}
                lsm={"40px"}
                lxs={"28px"}
                color={"#040404"}
              /> */}
              <NewTypography
                text={`Tel Support: +1.800.850.9795`}
                fsm={"22px"}
                fxs={"20px"}
                lsm={"40px"}
                lxs={"28px"}
                color={"#040404"}
              />
              <NewTypography
                text={`Fax: +1.505.213.0351`}
                fsm={"22px"}
                fxs={"20px"}
                lsm={"40px"}
                lxs={"28px"}
                color={"#040404"}
              />
            </Box>
            <Box>
              <EmailTypography
                text={`Email:`}
                email={"instruments@betterphysiology.com"}
                fsm={"22px"}
                fxs={"20px"}
                lsm={"40px"}
                lxs={"28px"}
              />
              <EmailTypography
                text={`Email Tech Support:`}
                email={"tech@betterphysiology.com"}
                fsm={"22px"}
                fxs={"20px"}
                lsm={"40px"}
                lxs={"28px"}
              />
              <NewTypographyTech
                text={` Click here`}
                text2={" for tech support"}
                link={"/go-service"}
                fsm={"22px"}
                fxs={"20px"}
                lsm={"40px"}
                lxs={"28px"}
                color={"#040404"}
              />
            </Box>
            {/* <Box py={"1.5rem"}>
            <NewTypography text={`Live support on Zoom:`} fsm={"24px"} fxs={"20px"} lsm={"40px"} lxs={"28px"} color={"#040404"} />
            <NewTypography text={`Professional GO subscribers only`} fsm={"24px"} fxs={"20px"} lsm={"40px"} lxs={"28px"} color={"#040404"}  />
            <NewTypography text={`Email Tech Support.`} fsm={"24px"} fxs={"20px"} lsm={"40px"} lxs={"28px"} color={"#040404"} />
            </Box> */}
          </Grid>
        </Grid>
        <Box
          sx={{
            height: { sm: "20vh" },
          }}
        />
        <Box pt={"5rem"}>
          <Copyright />
        </Box>
      </Box>
    </Container>
  );
};

export default ContactUs;

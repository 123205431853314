import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import logo from "../Assets/Images/logo.png";
import cart from "../Assets/Images/cart.png";
// import user from "../Assets/Images/user.png";
import { Link, NavLink, useLocation, useSearchParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  usePopupState,
  bindHover,
  bindPopper,
} from "material-ui-popup-state/hooks";
import { Popper } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Subscribe from "../Pages/Subscribe";
import MobileNav from "./MobileNav";
import Badge from "@mui/material/Badge";
import { useDispatch, useSelector } from "react-redux";
import { getCart } from "../store/cartSlice";

const useStyles = makeStyles({
  btn: {
    background: "rgb(109 79 160) !important",
    color: "#fff !important",
    fontFamily: "ABLE",
    padding: "10px 8px !important",
    lineHeight: "initial !important",
    "&:hover": {
      background: "rgb(109 79 160)",
    },
    border: "2px solid #C5A7CF !important",
    borderRadius: "6px !important",
  },
  submenuList: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#000",
    "&:hover": {
      color: "rgb(109 79 160) !important",
    },

    "@media (min-width:1500px)": {
      fontSize: "22.2px !important",
    },
    "@media (max-width:1499px)": {
      fontSize: "20px !important",
    },

    borderRadius: "4px",
    fontFamily: "ABLE !important",
  },
  menuLink: {
    color: "#000",
    display: "grid",
    borderRadius: "8px",
    "@media (min-width:1500px)": {
      fontSize: "22.2px !important",
    },
    "@media (max-width:1499px)": {
      fontSize: "20px !important",
    },
    fontFamily: "ABLE !important",
    textDecoration: "none",
    "&:hover": {
      color: "#rgb(109 79 160)",
    },
  },
});

const drawerWidth = 300;

function DrawerAppBar(props) {
  const dispatch = useDispatch();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const referer = searchParams.get("referer");
  React.useEffect(() => {
    if (referer) {
      localStorage.setItem("referer", referer);
    }
  }, [referer]);

  React.useEffect(() => {
    dispatch(getCart(localStorage.getItem("user")));
  }, [dispatch]);

  const { data: result } = useSelector((state) => state.cart);
  const popupState = usePopupState({
    variant: "popper",
    popupId: "demoPopper",
  });
  const popupState1 = usePopupState({
    variant: "popper",
    popupId: "demoPopper",
  });
  const popupState2 = usePopupState({
    variant: "popper",
    popupId: "demoPopper",
  });
  const popupState3 = usePopupState({
    variant: "popper",
    popupId: "demoPopper",
  });
  const popupState4 = usePopupState({
    variant: "popper",
    popupId: "demoPopper",
  });
  const navLinkStyles = ({ isActive }) => {
    return {
      color: isActive ? "rgb(109 79 160)" : "rgb(109 79 160)",
      // padding: '0rem 0.8rem',
      borderRadius: "8px",
      display: "flex",
      transition: "0.5s",
      fontFamily: "ABLE",
      textDecoration: "none",
      alignItems: "center",
      // fontWeight: isActive ? '700' : '500',
    };
  };

  const classes = useStyles();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [subscribeModal, setSubscribeModal] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <MobileNav
      handleDrawerToggle={handleDrawerToggle}
      cartValue={result?.data?.length}
    />
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          position: "inherit",
          backgroundColor: "transparent",
          boxShadow: "none",
          py: "0.6rem",
          pr: "0 !important",
          borderBottom: { md: "none", lg: "1px solid rgb(109 79 160)" },
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between", p: "0 !important" }}>
          <Link to={"/"}>
            {" "}
            <Typography component={"img"} src={logo} />
          </Link>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { lg: "none", color: "rgb(109 79 160)" }, p: "0" }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            sx={{
              display: { md: "none", lg: "flex", sm: "none", xs: "none" },
              gap: "1.2rem",
            }}
          >
            <NavLink style={navLinkStyles} to={"/"}>
              <Typography
                sx={{
                  borderRadius: "8px",
                  cursor: "pointer",
                  "@media (min-width:1500px)": {
                    fontSize: "22.2px !important",
                  },
                  "@media (max-width:1499px)": {
                    fontSize: "20px !important",
                  },
                  fontFamily: "ABLE",
                  color: "#000",
                  "&:hover": {
                    color: "rgb(109 79 160)",
                  },
                }}
              >
                HOME
              </Typography>
            </NavLink>
            <Typography
              className={classes.submenuList}
              {...bindHover(popupState)}
            >
              Company <KeyboardArrowDownIcon fontSize="0.8rem" />
            </Typography>
            <Typography
              className={classes.submenuList}
              {...bindHover(popupState1)}
            >
              Products <KeyboardArrowDownIcon fontSize="0.8rem" />
            </Typography>
            <Typography
              className={classes.submenuList}
              {...bindHover(popupState2)}
            >
              Education <KeyboardArrowDownIcon fontSize="0.8rem" />
            </Typography>
            <Typography
              className={classes.submenuList}
              {...bindHover(popupState3)}
            >
              Services <KeyboardArrowDownIcon fontSize="0.8rem" />
            </Typography>
            <Typography
              className={classes.submenuList}
              {...bindHover(popupState4)}
            >
              Software <KeyboardArrowDownIcon fontSize="0.8rem" />
            </Typography>
            {/* <NavLink style={navLinkStyles} to={"/subscribe"}> */}
            <Typography
              sx={{
                color: "#000",
                borderRadius: "8px",
                cursor: "pointer",
                "@media (min-width:1500px)": {
                  fontSize: "22.2px !important",
                },
                "@media (max-width:1499px)": {
                  fontSize: "20px !important",
                },
                fontFamily: "ABLE",
                textDecoration: "none",
                "&:hover": {
                  color: "rgb(109 79 160)",
                },
              }}
              href="/subscribe"
              component={"a"}
              // onClick={() => setSubscribeModal(true)}
            >
              Subscribe
            </Typography>
            {/* </NavLink> */}
            <NavLink style={navLinkStyles} to={"/faq"}>
              <Typography
                sx={{
                  borderRadius: "8px",
                  cursor: "pointer",
                  color: "#000",
                  "@media (min-width:1500px)": {
                    fontSize: "22.2px !important",
                  },
                  "@media (max-width:1499px)": {
                    fontSize: "20px !important",
                  },
                  fontFamily: "ABLE",
                  "&:hover": {
                    color: "rgb(109 79 160)",
                  },
                }}
              >
                FAQs
              </Typography>
            </NavLink>
          </Box>
          <Box
            sx={{
              display: { md: "none", lg: "flex", sm: "none", xs: "none" },
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Badge
              badgeContent={
                result?.data?.length > 0 ? result?.data?.length : "0"
              }
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: "#E4D5F4",
                  color: "#000000",
                  fontSize: "15px",
                  fontFamily: "ABLE",
                  height: "23px",
                  width: "23px",
                  borderRadius: "100%",
                },
              }}
            >
              <Button
                className={classes.btn}
                sx={{
                  fontSize: {
                    xl: "16.5px !important",
                    xs: "16px !important",
                  },
                }}
                component={Link}
                to="/cart"
              >
                Cart{" "}
                <Typography
                  ml={"0.5rem"}
                  component={"img"}
                  width={"16px"}
                  src={cart}
                />
              </Button>
            </Badge>

            {/* <Button
              className={classes.btn}
              component={Link}
              sx={{
                  fontSize: {
                    xl: "0.92rem !important",
                    xs: "0.7rem !important",
                  },
                }}
              to="/shopping-cart"
            >
              Buy Now
            </Button> */}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { md: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "#e4dbe7",
              padding: "1rem",
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Popper {...bindPopper(popupState)}>
        <Box
          sx={{
            background: "#E4D5F4",
            padding: "0.5rem 1rem",
            borderRadius: "0.3rem",
            width: "150px",
            position: "absolute",
            left: "-3rem",
          }}
        >
          <Link to={"/contact"} className={classes.menuLink}>
            Contact
          </Link>
          <Link className={classes.menuLink} to={"/distributors"}>
            Distributors
          </Link>
          <Link className={classes.menuLink} to={"/history"}>
            History
          </Link>
          <Link className={classes.menuLink} to={"/mission"}>
            Mission
          </Link>
        </Box>
      </Popper>
      <Popper {...bindPopper(popupState1)}>
        <Box
          sx={{
            background: "#E4D5F4",
            padding: "0.5rem 1rem",
            borderRadius: "0.3rem",
            width: "150px",
            position: "absolute",
            left: "-3rem",
          }}
        >
          <Link className={classes.menuLink} to={"/go-brochure"}>
            GO Brochure
          </Link>
          <Link className={classes.menuLink} to={"/shopping-cart"}>
            GO Buy
          </Link>
          <Link className={classes.menuLink} to={"/request-demonstration"}>
            GO Demo
          </Link>
          <Link className={classes.menuLink} to={"/go-overview"}>
            GO Overview
          </Link>
        </Box>
      </Popper>
      <Popper {...bindPopper(popupState2)}>
        <Box
          sx={{
            background: "#E4D5F4",
            padding: "0.5rem 0.4rem 0.5rem 0.8rem",
            borderRadius: "0.3rem",
            width: "200px",
            position: "absolute",
            left: "-3.5rem",
          }}
        >
          <Link className={classes.menuLink} to={"/book"}>
            Book
          </Link>
          <Link className={classes.menuLink} to={"/course"}>
            Course
          </Link>
          <Link className={classes.menuLink} to={"/interview"}>
            Interviews
          </Link>
          <Link className={classes.menuLink} to={"/lectures"}>
            Lectures
          </Link>
          <Link className={classes.menuLink} to={"/references"}>
            References
          </Link>
          <Link className={classes.menuLink} to={"/professional-education"}>
          Professional School
          </Link>
        </Box>
      </Popper>
      <Popper {...bindPopper(popupState3)}>
        <Box
          sx={{
            background: "#E4D5F4",
            padding: "0.5rem 1rem",
            borderRadius: "0.3rem",
            width: "150px",
            position: "absolute",
            left: "-3rem",
          }}
        >
          <Link className={classes.menuLink} to={"/consultation"}>
            Consultation
          </Link>
          <Link className={classes.menuLink} to={"/go-service"}>
            GO Services
          </Link>
          <Link className={classes.menuLink} to={"/go-video"}>
            GO Videos
          </Link>
          <Link className={classes.menuLink} to={"/training"}>
            Training
          </Link>
        </Box>
      </Popper>
      <Popper {...bindPopper(popupState4)}>
        <Box
          sx={{
            background: "#E4D5F4",
            padding: "0.5rem 1rem",
            borderRadius: "0.3rem",
            width: "175px",
            position: "absolute",
            left: "-3rem",
          }}
        >
          {" "}
          <Link className={classes.menuLink} to={"/go-download"}>
            GO Downloads
          </Link>
          <Link className={classes.menuLink} to={"/go-manual"}>
            GO Manuals
          </Link>
          <Link className={classes.menuLink} to={"/go-quickstart"}>
            GO Quickstart
          </Link>
          <Link className={classes.menuLink} to={"/download-5.0"}>
            5.0 Instruments
          </Link>
        </Box>
      </Popper>

      {/* ***subscribe modal**** */}
      {/* <Subscribe
        subscribeModal={subscribeModal}
        setSubscribeModal={setSubscribeModal}
      /> */}
    </Box>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;
